:root {
  --main-color: #3f4042;
  --sub-color: rgb(145, 145, 145);
  --bg-gradation: linear-gradient(180deg, var(--main-color) 10%, var(--sub-color) 100%);
}

#page {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#header {
  border-bottom: solid 1px;
  background: rgb(144, 144, 144);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  z-index: 999;

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }

  h1.logo {
    padding: 0 20px 0;
    a {
      display: block;
    }
  }

  ul.menu {
    li a {
      line-height: 50px;
      font-size: 1.1rem;
      color: #000;
      text-align: center;
      &:hover {
        background-color: #e1e1e1;
      }
    }
  }
}

#contents {
  width: 95%;
  margin: 90px auto 0;
  max-width: 900px;

  #bread01 {
    height: 15px;
    margin-bottom: 20px;
    margin-left: 50px;
    font-size: 90%;

    ol.bread02 li {
      display: inline;
      padding-left: 10px;
      color: #000;

      a {
        font-style: normal;

        &:hover {
          color: #00f;
        }
      }
    }
  }
}

#footer {
  width: 100%;
  padding: 10px 0 20px;
  color: #fff;
  background: var(--bg-gradation);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: auto;

  p {
    width: 100%;
    text-align: center;
  }

  .sitemap {
    margin-top: 10px;
    color: #fff;
  }

  address {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
